/* eslint-disable @typescript-eslint/no-explicit-any */
import dynamic from 'next/dynamic';
import DeliveryAndKlarnaSchema from '@amplience/content-types/src/content-schemas/c-delivery-and-klarna.json';
import DoubleBannerSchema from '@amplience/content-types/src/content-schemas/c-double-banner.json';
import FullBannerSchema from '@amplience/content-types/src/content-schemas/c-full-banner.json';
import GiftCardBalanceSchema from '@amplience/content-types/src/content-schemas/c-gift-card-balance.json';
import InformatoryTextSchema from '@amplience/content-types/src/content-schemas/c-informatory-text.json';
import PDPAccordionSchema from '@amplience/content-types/src/content-schemas/c-pdp-accordion.json';
import PdpStockLevelSchema from '@amplience/content-types/src/content-schemas/c-pdp-stock-level.json';
import ProductCareTableSchema from '@amplience/content-types/src/content-schemas/c-product-care-table.json';
import ProductFeatureSchema from '@amplience/content-types/src/content-schemas/c-product-feature.json';
import ProductTextBlockSchema from '@amplience/content-types/src/content-schemas/c-product-text-block.json';

/* eslint-disable promise/prefer-await-to-then */
const DeliveryAndKlarna = dynamic(() =>
  import('./deliveryAndKlarna/DeliveryAndKlarna').then((mod) => mod.DeliveryAndKlarna),
);

const DoubleBannerComponent = dynamic(() =>
  import('@storefront/lib-content/components/singleAndOverlappingBanners/DoubleBannerComponent').then(
    (mod) => mod.DoubleBannerComponent,
  ),
);

const FullBannerComponent = dynamic(() =>
  import('@storefront/lib-content/components/singleAndOverlappingBanners/FullBannerComponent').then(
    (mod) => mod.FullBannerComponent,
  ),
);

const PDPAccordion = dynamic(() => import('./PDPAccordion').then((mod) => mod.PDPAccordion));

const StockIndicator = dynamic(() => import('./StockIndicator').then((mod) => mod.StockIndicator));

const ProductFeature = dynamic(() => import('./ProductFeature').then((mod) => mod.ProductFeature));

const ProductCareTable = dynamic(() =>
  import('./materialsAndCare/ProductCareTable').then((mod) => mod.ProductCareTable),
);

const ProductTextBlock = dynamic(() =>
  import('./materialsAndCare/ProductTextBlock').then((mod) => mod.ProductTextBlock),
);

const InformatoryText = dynamic(() => import('./InformatoryText').then((mod) => mod.InformatoryText));

const CheckGiftCardBalance = dynamic(() =>
  import('./giftCardBalance/CheckGiftCardBalance').then((mod) => mod.CheckGiftCardBalance),
);

/* eslint-enable promise/prefer-await-to-then */
export function createPDPAmplienceComponent<T extends unknown[]>(array: T): any {
  const components: any = {};
  array?.forEach((comp) => {
    const componentProps = comp as any;

    if (!componentProps?._meta) {
      return;
    }

    if (componentProps._meta.schema === DeliveryAndKlarnaSchema.$id) {
      components[DeliveryAndKlarnaSchema.$id] = DeliveryAndKlarna;
    } else if (componentProps._meta.schema === DoubleBannerSchema.$id) {
      components[DoubleBannerSchema.$id] = DoubleBannerComponent;
    } else if (componentProps._meta.schema === FullBannerSchema.$id) {
      components[FullBannerSchema.$id] = FullBannerComponent;
    } else if (componentProps._meta.schema === PDPAccordionSchema.$id) {
      components[PDPAccordionSchema.$id] = PDPAccordion;
    } else if (componentProps._meta.schema === PdpStockLevelSchema.$id) {
      components[PdpStockLevelSchema.$id] = StockIndicator;
    } else if (componentProps._meta.schema === ProductFeatureSchema.$id) {
      components[ProductFeatureSchema.$id] = ProductFeature;
    } else if (componentProps._meta.schema === ProductCareTableSchema.$id) {
      components[ProductCareTableSchema.$id] = ProductCareTable;
    } else if (componentProps._meta.schema === ProductTextBlockSchema.$id) {
      components[ProductTextBlockSchema.$id] = ProductTextBlock;
    } else if (componentProps._meta.schema === InformatoryTextSchema.$id) {
      components[InformatoryTextSchema.$id] = InformatoryText;
    } else if (componentProps._meta.schema === GiftCardBalanceSchema.$id) {
      components[GiftCardBalanceSchema.$id] = CheckGiftCardBalance;
    }
  });

  return components;
}
